































import { defineComponent, ref, toRefs } from "@vue/composition-api";
import { TemplatesClient, TemplateSummary } from "@/api/OtiumAppApi";

export default defineComponent({
  name: "DashboardArchivedTemplateSummary",
  props: {
    template: {
      type: TemplateSummary,
      required: true,
    },
  },
  setup(props, { root }) {
    const { template } = toRefs(props);

    const hovered = ref(false);

    const displayUnarchiveTemplateDialog = ref(false);
    const unarchivingTemplate = ref(false);
    async function unarchiveTemplate() {
      unarchivingTemplate.value = true;
      const client = new TemplatesClient();
      await client.unarchiveTemplate(template.value.id);
      unarchivingTemplate.value = false;
      displayUnarchiveTemplateDialog.value = false;
      root.$router.push({name: "Templates"});
    }

    return {
      hovered,
      displayUnarchiveTemplateDialog,
      unarchivingTemplate,
      unarchiveTemplate,
    }
  }
});
