















































import Vue from "vue";
import { ref, defineComponent, onMounted, computed } from "@vue/composition-api";
import OtiumBasePage from "@/components/OtiumBasePage.vue";
import DashboardArchivedTemplateSummary from "@/components/Templates/DashboardArchivedTemplateSummary.vue";
import { CreateTemplateModel, ICreateTemplateModel, TemplatesClient, TemplateSummary, TemplateTagModel } from "@/api/OtiumAppApi";

export default defineComponent({
  name: "ArchivedTemplates",
  components: { OtiumBasePage, DashboardArchivedTemplateSummary },
  props: {},
  setup(props, { root }) {
    const templates = ref<Array<TemplateSummary>>([]);
    const client = new TemplatesClient();
    const tags = ref({} as {[key: string]: TemplateTagModel});
    const filterTag = ref(null as string | null);

    const sortedTags = computed(() => {
      const tagMap = tags.value;
      const sortedTags: TemplateTagModel[] = [];
      Object.keys(tagMap).forEach((key) => {
        sortedTags.push(tagMap[key]);
      });
      return sortedTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    });

    const fetchTemplates = async () => {
      templates.value = await client.getArchivedTemplates();
      templates.value.forEach(template => template.tags.forEach(tag => {
        const tagMap = tags.value;
        if (tagMap[tag.tagId] == undefined)
          Vue.set(tagMap, tag.tagId, tag);
      }));
    }

    onMounted(async () => {
      await fetchTemplates();
    });

    const searchTerm = ref("");

    const filteredTemplates = computed(() =>
      templates.value.filter((template) =>
        template.name.toLocaleUpperCase().includes(searchTerm.value.toLocaleUpperCase()) && (
          filterTag.value == null || template.tags.some(tag => tag.tagId == filterTag.value)
        )
      )
    );

    const createTemplate = async (tplName: string, tplSummary: string) => {
      const model: ICreateTemplateModel = {
        name: tplName,
        summary: tplSummary,
      };
      await client.createTemplate(new CreateTemplateModel(model));
      await fetchTemplates();
    };

    const goToTemplates = () => {
      root.$router.push({name: "Templates"});
    }

    return {
      templates,
      sortedTags,
      filterTag,
      createTemplate,
      searchTerm,
      filteredTemplates,
      goToTemplates,
    };
  }
});
